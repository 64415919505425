<template>
  <div>
    <div class="button-box">
      <el-button
        size="small"
        :disabled="multipleSelection.length == 0"
        @click="handleClearSelection()"
      >取消选择</el-button>
      <el-button
        size="small"
        :disabled="multipleSelection.length == 0"
        @click="handleDeleteSelection()"
      >删除</el-button>
    </div>
    <el-table
      ref="newsListTable"
      :data="tableData"
      @selection-change="handleSelectionChange"
    >
      <!-- 多選 -->
      <el-table-column
        type="selection"
        fixed="left"
        width="55"
      >
      </el-table-column>
      <!-- 排序 -->
      <el-table-column
        prop="sort"
        label="排序"
        min-width="50"
        align="center"
      />
      <!-- 标题 -->
      <el-table-column
        prop="title"
        label="标题"
        min-width="250"
        align="center"
      />
      <!-- 图片显示 -->
      <el-table-column
        label="图片显示"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.photo }}
          <el-image
            style="width: 100px; height: 100px"
            :src="$common.resSrc(scope.row.photo)"
            fit="contain"
          >
            <div
              slot="placeholder"
              class="image-slot"
            >
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <!-- 发文时间 -->
      <el-table-column
        prop="start_at"
        label="发文时间"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <!-- 点击数 -->
      <el-table-column
        prop="clicks"
        label="点击数"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <!-- 状态 -->
      <el-table-column
        label="状态"
        fixed="right"
        min-width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-switch
            @change="handleChangeState($event, scope.row)"
            :disabled="!editable"
            v-model="scope.row.is_visible"
            active-value="1"
            inactive-value="0"
          />
          {{ scope.row.is_visible === "1" ? "上架" : "下架" }}
        </template>
      </el-table-column>
      <!-- 来源 -->
      <el-table-column
        prop="source"
        label="来源"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <!-- 责编 -->
      <el-table-column
        prop="author"
        label="责编"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <!-- 操作 -->
      <el-table-column
        label="操作"
        fixed="right"
        min-width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button-group>
            <el-tooltip
              effect="dark"
              content="查看"
              placement="top"
            >
              <el-button
                type="primary"
                icon="el-icon-view"
                @click="handleView(scope.row)"
              />
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="修改"
              placement="top"
            >
              <el-button
                :disabled="!editable"
                type="success"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)"
              />
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="刪除"
              placement="top"
            >
              <el-button
                :disabled="!removal"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
              />
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { changeTrickState } from '@/api/admin/trick'

export default {
  data () {
    return {
      multipleSelection: []
    }
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    removal: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleChangeState ($event, row) {
      this.handleEditable(false)
      const status = $event
      changeTrickState(row.id, status)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('handleRefresh')
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
          this.handleEditable(true)
        })
        .catch(err => {
          this.$message.error(err)
          this.handleEditable(true)
        })
    },
    handleEditable (boo) {
      this.$emit('handleEditable', boo)
    },
    handleEdit (row) {
      this.$router.push({
        name: 'AdminMarketTrickEdit',
        params: {
          id: row.id
        }
      })
    },
    handleDelete (row) {
      this.$emit('handleDelete', row.id)
    },
    handleDeleteSelection () {
      const idList = this.multipleSelection.map((item) => {
        return item.id
      })
      this.$emit('handleDelete', idList.join(','))
    },
    handleClearSelection () {
      this.$refs.newsListTable.clearSelection()
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    handleView (row) {
      // this.showContent = row
      this.$emit('handlePreview', row)
      // this.showDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.button-box {
  text-align: left;
  margin: 20px 0 10px;
}
.table-richtext-col {
  max-height: 140px;
  overflow-y: scroll;
  transform: scale(0.85);
}
</style>
